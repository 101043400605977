.common-layout .page-container .main-content{
    background: #eaeef2;
}
.logo img{
 max-width: 100%;
}
// .common-layout .page-container{
//     padding-left: 0;
// } 

.owl-carousel.owl-drag .owl-item{
    width: 120px !important;
    height: 120px !important;
}
.p-carousel .p-carousel-indicators{
    display: none !important;
  }
  .jewell .p-carousel-items-content {
    position: relative;
  }
  .jewell .p-carousel .p-carousel-content .p-carousel-prev{
    position: absolute;
    left: -40px;
    top: 50%;
  }
.jewell .p-carousel .p-carousel-content .p-carousel-next{
    position: absolute;
    right: -40px;
    top: 50%;
}
.jewell .p-carousel .p-carousel-content .p-carousel-prev:hover,
.jewell .p-carousel .p-carousel-content .p-carousel-next:hover{
    background:#fff;
}

.ant-tabs-nav .ant-tabs-tab{
    margin-right: 0;
}
@media (min-width: 1400px) {
    .container {
        width: 1366px;
        max-width: 100%;
    }
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar{
    height: 44px;
    border-radius: 4px;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #fff; 
    z-index: 1;
    position: relative;
}

.ant-tabs-tab-btn{
    text-transform: capitalize;
}
.side-nav .side-nav-menu {
    padding-left: 15px;
    padding-right: 10px;
}


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background: #832A30;
        color: #fff;
        overflow: visible;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
    color: #fff;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
    height: 70px;
    line-height: 70px;
    border-radius: 6px;
}


.ant-menu-inline > .ant-menu-item, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 70px;
    line-height: 70px;
    
    border-radius: 6px;
}


.ant-menu-inline .ant-menu-item::after{
    left: -15px;
    right: auto;
    border-right: 8px solid #832A30;
    border-radius: 0 4px 4px 0;

}


.border-radius-custom {
    border-radius: 20px; 
}
.border-radius-custom .ant-card-body{
    padding: 0;
   
}


.p-carousel-items-content{
    background-color: #fff;

    border-radius: 20px;    
}

.gap-4{
    gap: 10px;
}
@media only screen and (min-width: 992px) {
    // .common-layout.is-folded .page-container {
    //     padding-left: 0px;
    // }

    .logo {
        display: none;
    }

    
}

.side-nav{
     top: 0;
}



.ant-menu-submenu-title{
    background: #832A30;
    color: #fff;
    overflow: visible;
}

.ant-menu-submenu-title:active{
    background: #832A30;
    color: #fff;
    overflow: visible;
    position: relative;
}


.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title{
    position:relative;
    overflow: visible;
    display: block;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title::before{
    left: -15px;
    right: auto;
    border-right: 8px solid #832A30;
    border-radius: 0 4px 4px 0;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0
 
}

.ant-menu .active{
    color: #832A30 !important;
    background: #832A30;
    background: rgba(131, 42, 48, 0.2);
}
 
.ant-menu .ant-menu-item{
    pointer-events: none;
}
 

 
.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow{
    color: #fff;
}

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before{
    background: linear-gradient(to right, #ffffff, #ffffff);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before{
    background: linear-gradient(to right, #ffffff, #ffffff);
}
.ant-menu-submenu-title span{
 
    color: #fff; 
}

.logo-sidenav{
    padding-top: 15px;
}


perfect-scrollbar>.ps
{
    overflow-y: visible !important;
}


.disable-class {
    background-color: gray;
    pointer-events: none;
}
.disable-class:hover {
    background-color: gray;
}
@media only screen and (max-width: 992px) {
    .jewell .p-carousel .p-carousel-content .p-carousel-prev{
        position: absolute;
        left: 0px;
        top: 50%;
        z-index: 9;
        background-color: #fff;
      }
    .jewell .p-carousel .p-carousel-content .p-carousel-next{
        position: absolute;
        right: 0px;
        background-color: #fff;
        top: 50%;
    }
 
        .header .logo {
            display: block;
            width: 100%;
        }
        .header .logo > a{
            text-align: left;
        }
     
}